import logo from './icrew.png';
import './App.css';
import QRCode from 'qrcode.react'
import React from 'react';
import axios from 'axios';
import { Buffer } from 'buffer';

class Content extends React.Component {
  config = require('./env_config.json')[process.env.NODE_ENV]
  _params = new URLSearchParams(window.location.search)
  accomId = this._params.get('aid')
  saleId = this._params.get('sid')

  state = {
    accomName: '',
    roomName: '',
    otp: '',
    qrOtp: ''
  }

  getOtp = () => {
    var token = Buffer.from(this.config.app_id + ":" + this.config.app_secret, 'utf8').toString('base64');
    return axios.get(`${this.config.api_url}/v1/door-lock/otp/${this.accomId}/${this.saleId}`, {
      headers: { 
        'accept': 'application/json' ,
        'Authorization': `Basic ${token}` 
      }
    })
  }

  otpRefresh = () => {
    return this.getOtp()
    .then(res => {
      const d = res.data
      if (d.success) {
        this.setState({
          accomName: d.accom_display_name,
          roomName: `호실: ${d.room_display_name}`,
          otp: `비밀번호: ${d.otp}*`,
          qrOtp: d.qr_otp_v1
        })
      } else {
        throw new Error(d.error_message)
      }
    })
    .catch(err => {
      console.log(err)
      this.setState({
        roomName: err.message,
        otp: '',
        qrOtp: ''
      })
    })
  }


  constructor(props) {
    super(props)
    this.otpRefresh()
    setInterval(() => {
      this.otpRefresh()
    }, 1000 * 60 * 5);
  }

  render() {
    return (
        <div>
          <p>{this.state.accomName}</p>
          <p>{this.state.roomName}</p>
          <QRCode size={150} includeMargin={true} value={this.state.qrOtp} />
          <p>{this.state.otp}</p>
          {/* <button onClick={this.otpRefresh}>새로고침</button> */}
        </div>
    );
  }
}


function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <Content> </Content>
      </header>
    </div>
  );
}

export default App;
